body {
    background: rgba(45,45,45,1) !important;
}
p {
    border: 0px !important;
    margin: 0px !important;
}

.ai_headline {
    text-align:left;
    color: black;
    margin: 0px;
    padding: 0px;
    font-size: 24px;
    margin-top: 10px;
}

.url {
    text-align: right;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
    color: blue;
    text-decoration: none;
    display: flex;
    flex-direction: row;
}

.disclosure_plane {
    /* background-color: #F6F6F6; */
    color: white;
    font-size: 16px;
    font-weight: Regular;
    margin: 0px 10px 20px 0px;
}

.center_align_spinner {
    margin-top: 10px;
    margin-bottom: 20px;
}

.spinner_disclosure {
    text-align: center;
    font-size: 16px;
}

.disclosure_input {
    margin: 10px 0px;
    padding: 10px;
    min-width: 300px;
    background-color: #737373;
    font-weight: bold;
    border-radius: 8px;
    color: white;
    border: 0px;
    text-align: center;
}

.disclosure_input::placeholder {
    text-align: center;
}

a:visited {
    color: #6B98FF;
    text-decoration: none;
}

.description {
    text-align: left;
    font-size: 15px;
}

.link_item_group {
    background-color: #F6F6F6;
    margin: 10px 10px 10px 30px;
    padding: 20px 40px;
    border-radius: 10px;
    min-width: 100%;
}

p {
    margin: 0px;
    padding: 0px;
    padding-top: 5px;
    text-align: left;
}

.details_group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: black;
}

.date_string {
    align-self: left;
    flex-grow: 999;
    font-size: 14px;
    font-weight: 600;
    padding: 0px;
    margin: 0px;
}

.dashboard_menu_bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 10px;
}

.current_date_display {
    flex-grow: 999;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.dashboard_subtitle {
    font-size: 14px;
}

.loadingBar {
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    position: absolute;
    left: calc(50% - 75px);
    top: calc(50% - 75px)
}

.data_plane {
    width: 100%;
    min-height: calc(100%-200px -50px);
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.ai_disclaimer {
    text-align: center;
    font-size: 10px;
    margin: 10px 0px 0px 0px;
    text-align: center;
    color: #C2C2C2

}


.rendered_data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 20px 0px 0px;
  }
@media only screen and (min-width: 768px) {
    /* For desktop: */
    .data_plane {
        width: 100%;
        min-height: calc(100%-200px -50px);
        max-width: 80%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 20px;
    }    

    .disclosure_plane {
        width: 100%;
        max-width: initial;
    }

    .disclosure_plane_wrapper {
        width: 50%;
        max-width: 480px;
        margin: 0px 15px;
    }

    .ai_disclaimer {
        text-align: center;
        max-width: initial;
    }

    .link_item_group {
        margin: 0px 10px 20px 10px;
        max-width: 600px;
    }

    .rendered_data {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 50%;
        flex-grow: 999;
        margin: 0px 0px 0px 40px;
    }
  }
  
.dashboard_menu_bar {
    padding: 0px 30px;
    margin: 10px 0px;
}

.build_button {
    background-color: #0C7CFF;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 22px;
    font-weight: bold;
    border: 0px;
    color: white;
    cursor: pointer;
}

.build_button:hover {
    background-color: #0053B2;
}

.build_button:disabled {
    background-color: darkgray;
}

.build_cta {
    margin: 15px 0px 18px 0px;
}

.remove_btn {
    cursor: pointer;
    color: #C6A5A5;
    margin-left: 8px;
    text-align: right;
    text-decoration: underline;
}

.remove_btn:hover {
    color: #FA5E5E;
}

.article_link_btn:hover {
    color: #79BBFF;
}

.center_align {
    width: 100%;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.articles_error {
    font-size: 14px;
    text-align: center;
}

.time-window-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
}

.MuiInputBase-input {
    color: white;
    background-color: white !important;
    border-radius: 5px;
}

.disclosure_input {
    margin: 20px 0px;
}

.disclosure_p {
    line-height: 125%;;
}

.lookback_window_toggle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    margin: 0px 0px 20px 0px;
}

.lookback_window_toggle > p {
    font-size: 18px;
    width: 80%;
}

.lookback_select {
    max-width: 200px;
}

.ui_window {
    background-color: #222222;
    padding: 20px 40px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.loading_disclaimer {
    font-size: 24px;
    text-align: center;
}

.lookback_window_wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.add_cta {
    margin: 0px 20px 20px 20px;
    padding: 20px 0px;
}

.add_articles_button {
    background-color: #0C7CFF;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    border: 0px;
    color: white;
    cursor: pointer;
}