@import url("https://fonts.googleapis.com/css2?family=Gantari:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=PT+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800&display=swap");

.App {
  text-align: center;
  min-height: calc(100%-200px -50px);
  height: 100%;
  font-family: "DM Sans", Arial, Helvetica, sans-serif;
}

html {
  background: rgba(45, 45, 45, 1);
}

#root,
body,
html {
  width: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
}

.redense_toolbar {
  width: 100%;
  background-color: #111111;
  border-bottom: 2px solid #013b7e;
  min-height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.redense_footer {
  width: 100%;
  background-color: #1d1d1d;
  color: white;
  min-height: 80px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-weight: bold;
  bottom: 0px;
}

.ownership_bar {
  text-align: left;
  font-weight: 400;
}

.ownership_bar > p,
.ownership_bar > a {
  font-size: 0.8em;
}

.footer_container {
  margin: 30px 40px;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}

.redense_logo {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.menu_link_bar {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 15px 0px 0px 0px;
}

.separator {
  flex-grow: 3;
}

.toolbar_container {
  margin: 0px 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.signin_disclaimer {
  padding: 0px 15px;
  margin: 0px;
  font-size: 0.85em;
  color: white;
  font-weight: bold;
  text-decoration: none;
  font-weight: 600;
}

.signin_disclaimer > p {
  padding: 0px;
  font-size: 1em;
}

.footer_item,
.footer_item:visited {
  color: white;
  font-weight: 400;
  font-size: 16px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  min-height: 100%;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.data-display {
  list-style-type: none;
}

.authflow-header {
  padding: 0px;
  margin: 10px 0px;
}

.authflow-description {
  padding: 0px;
  margin: 0px;
}

.auth-form {
  margin: 10px 0px;
  padding: 0px;
}

.auth-switch {
  margin: 10px 0px 20px 0px;
}

.instructions {
  width: 80%;
  padding: 0px 10px;
}

.data-display {
  text-align: left;
  padding: 0px;
  margin: 0px;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.signin_disclaimer {
  cursor: pointer;
}

.drop_style {
  color: white !important;
  text-decoration: none;
  cursor: pointer;
}

.pointer {
  cursor: pointer !important;
}

@media only screen and (min-width: 768px) {
  .toolbar_container {
    margin: 0px 80px;
  }
}

.no_underline {
  text-decoration: none;
}

.hide_on_mobile {
  display: none;
}

.hide_on_desktop {
  display: initial;
}
@media only screen and (min-width: 768px) {
  .footer_container {
    margin: 30px 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .menu_link_bar {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0px 20px;
  }

  .hide_on_mobile {
    display: initial;
  }

  .hide_on_desktop {
    display: none;
  }
}

.font_bold {
  font-weight: 600;
}

.text_center {
  text-align: center;
}

.font_large {
  font-size: 2.4em;
}

.signout_item {
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  text-align: right;
}

.signout_item > .email_part {
  font-size: 90%;
  font-weight: 400;
}

.premium_badge {
  padding: 2px 5px;
  color: white;
  text-decoration: none !important;
  font-size: 80%;
  background-color: #1a71ff;
  border-radius: 5px;
  margin-left: 8px;
}
