.add-article-modal-custom {
    z-index: 999;
    position: absolute;
    background-color: white;
    border-radius: 15px;
    margin: 10px;
    color: black;
    top: 10px;
    max-width: 600px;
    min-width: 350px;
    padding: 20px;

}

.generated_headline {
    font-size: 24px;
    padding: 0px;
    margin: 0px;
}

.newsletter_instructions {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
}

.content {
    font-size: 14px;
    text-align: left;
    margin-top: 20px;
}

.add-article-modal {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
}

.actions {
    border-top: 0.5px solid #CDCDCD;
}

.dismiss_button {
    background-color: #0C7CFF;
    border-radius: 8px;
    padding: 10px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    border: 0px;
    color: white;
    cursor: pointer;
}

.loadingBarModal {
    margin: 10px 0px;
}

.newsletter-wrapper {
    max-width:  600px;
    line-height: 120%;
}

.headline-back {
    background-color:  #5D9FFF;
    height: 150px;
    display: flex;
    flex-direction: row;
}

.date-str {
    color:  white;
    font-size:  36px;
    font-family:  Arial;
    margin:  115px 0px 0px 10px;
    text-align: center
}

.summary-text {
    margin:  30px 0px 0px 0px;
}

.list-of-links {
    margin:  30px 0px 20px 0px;
}

.body-text {
    padding:  0px 10px;
}

.add-article-header {
    margin: 0px;
    font-size: 26px;
    font-weight: bold;
}

.add-article-header-wrapper {
    border-bottom: 1px solid #F2F2F2;
}

.add-article-modal-textarea {
    margin: 20px 0px;
}

.add-article-modal-description {
    margin: 20px 0px;
}

.found_link_item {
    font-size: 16px;
}


.dismiss_button {
    background-color: #E60000;
    border-radius: 8px;
    padding: 10px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    border: 0px;
    color: white;
    cursor: pointer;
    margin: 0px 5px;
    max-height: 60px;
    min-width: 80px;
}

.save_links_button {
    background-color: #0C7CFF;
    border-radius: 8px;
    padding: 10px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    border: 0px;
    color: white;
    cursor: pointer;
    margin: 0px 5px;
    max-height: 60px;
    min-width: 80px;
}

.save_links_button:disabled {
    background-color: #AFAFAF
}

.save_spinner {
    height: 25px;
}