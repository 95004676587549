.contact_container {
    width: 100%;
}

.contact_us_email,
.contact_us_email:visited {
    font-size: 0.9em;
    color: white;
}

.contact_us_options {
    margin: 40px 0px;
}
.contact_container {
    margin: 20px;
}
@media only screen and (min-width: 768px) {
    .contact_container {
        margin: 20%;
    }
}