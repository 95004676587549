* {
    box-sizing: border-box;
  }
  body {
    font-size: 14px;
  }
  .login_wrapper {
    /* background: url("../images/v3_20.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 60px;
    padding-bottom: 80px;
  }
  .v1_2 {
    width: 100%;
    background: rgba(45,45,45,1);
    opacity: 1;
    position: relative;
  }
  .login_inner_wrapper {
    width: 100%;
    max-width: 400px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    overflow: hidden;
  }
  .login_header {
    width: 100%;
    color: rgba(255,255,255,1);
    top: 0px;
    left: 106px;
    font-weight: Regular;
    font-size: 32px;
    opacity: 1;
    text-align: center;
  }

  .auth_container {
    width: 100%;
    height: 100%;
  }
  .v1_13 {
    width: 326px;
    height: 51px;
    /* background: url("../images/v1_13.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    overflow: hidden;
  }
  .v1_5 {
    width: 326px;
    height: 51px;
    background: rgba(241,241,241,1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(121,121,121,1);
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    overflow: hidden;
  }
  .textfield::placeholder {
    text-align: left;
  }
  .textfield {
    width: calc(100% - 40px);
    border: 0;
    color: rgba(91,91,91,1);
    background: rgba(241,241,241,1);
    border: 1px solid rgba(121,121,121,1);
    text-align: left;
    font-weight: Regular;
    font-size: 1.2rem;
    height: 40px;
    border-radius: 8px;
    padding: 25px;
    opacity: 1;
    margin: 10px 0px;
  }

  .textfield::placeholder {
    font-weight: Regular;
    /* padding: 20px; */
  }

  .textfield::placeholder,
  .textfield::before,
  .textfield::cue {
    font-weight: Regular;
  }

  .v1_12 {
    width: 326px;
    height: 51px;
    /* background: url("../images/v1_12.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 125px;
    left: 0px;
    overflow: hidden;
  }
  .v1_7 {
    width: 326px;
    height: 51px;
    background: rgba(241,241,241,1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(121,121,121,1);
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    overflow: hidden;
  }
  .v1_8 {
    width: calc(100% - 40px);
    border: 0;
    color: rgba(91,91,91,1);
    background-color: rgba(91,91,91,0);
    position: absolute;
    top: 14px;
    left: 20px;
    font-weight: Regular;
    font-size: 20px;
    opacity: 1;
    text-align: left;
  }

  .v1_9 {
    width: 114px;
    height: 50px;
    background: rgba(60,138,255,1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(113,169,253,1);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
  }
  .cta_button_inner {
    width: calc(100%);
    height: 100%;
    border: 0;
    background-color: rgba(91,91,91,0);
    color: rgba(255,255,255,1);
    position: absolute;
    top: 14px;
    left: 0px;
    font-weight: Regular;
    font-size: 20px;
    opacity: 1;
    cursor: pointer;
    background: rgba(60,138,255,1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(113,169,253,1);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    height: 50px;
  }

  .cta_button_inner:hover {
    background-color: #6AA6FF;
    -webkit-transition: background-color 100ms linear;
    -ms-transition: background-color 100ms linear;
    transition: background-color 100ms linear;
  }

  * {
    box-sizing: border-box;
  }
  body {
    font-size: 14px;
  }

  .signup_wrapper {
    /* background: url("../images/v3_21.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    margin-top: 60px;
    position: relative;
    width: 100%;
    top: 0px;
    left: 0px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .v1_15 {
    background: rgba(45,45,45,1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  .signup_login_wrapper {
    width: 100%;
    max-width: 400px;
    /* background: url("../images/v1_16.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    top: 70px;
    left: 204px;
  }
  .v1_17 {
    width: 166px;
    color: rgba(255,255,255,1);
    position: absolute;
    top: 0px;
    left: 80px;
    font-weight: Regular;
    font-size: 32px;
    opacity: 1;
    text-align: center;
  }
  .v1_18 {
    width: 326px;
    height: 51px;
    /* background: url("../images/v1_18.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 56px;
    left: 0px;
    overflow: hidden;
  }
  .v1_19 {
    width: 326px;
    height: 51px;
    background: rgba(241,241,241,1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(121,121,121,1);
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    overflow: hidden;
  }
  .v1_20 {
    width: calc(100%-40px);
    border: 0;
    color: rgba(91,91,91,1);
    background-color: rgba(91,91,91,0);
    position: absolute;
    top: 14px;
    left: 20px;
    font-weight: Regular;
    font-size: 20px;
    opacity: 1;
    text-align: left;
  }
  .v1_21 {
    width: 326px;
    height: 51px;
    /* background: url("../images/v1_21.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 125px;
    left: 0px;
    overflow: hidden;
  }
  .v1_22 {
    width: 326px;
    height: 51px;
    background: rgba(241,241,241,1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(121,121,121,1);
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    overflow: hidden;
  }
  .v1_23 {
    width: calc(100%-40px);
    border: 0;
    color: rgba(91,91,91,1);
    background-color: rgba(91,91,91,0);
    position: absolute;
    top: 14px;
    left: 20px;
    font-weight: Regular;
    font-size: 20px;
    opacity: 1;
    text-align: left;
  }
  .v1_27 {
    width: 326px;
    height: 51px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    left: 0px;
    overflow: hidden;
  }
  .v1_28 {
    width: 326px;
    height: 51px;
    background: rgba(241,241,241,1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(121,121,121,1);
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    overflow: hidden;
  }
  .v1_29 {
    width: calc(100%-40px);
    border: 0;
    color: rgba(91,91,91,1);
    background-color: rgba(91,91,91,0);
    position: absolute;
    top: 14px;
    left: 20px;
    font-weight: Regular;
    font-size: 20px;
    opacity: 1;
    text-align: center;
  }
  .cta_button {
    width: 114px;
    height: 50px;
    /* background: url("../images/v1_24.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    cursor: pointer;
  }
  .v1_25 {
    width: 114px;
    height: 50px;
    background: rgba(60,138,255,1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(113,169,253,1);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
  }
  .v1_26 {
    width: calc(100%);
    border: 0;
    background-color: rgba(91,91,91,0);
    color: rgba(255,255,255,1);
    position: absolute;
    top: 14px;
    left: 0px;
    font-weight: Regular;
    font-size: 20px;
    opacity: 1;
    text-align: center;
    cursor: pointer;
  }

  .textfield:focus { /* You can also use .element.text:focus here */
    border-color: rgba(0,0,0,0);
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0), 0 0 8px rgba(0,0,0,0);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0), 0 0 8px rgba(0,0,0,0);
  }  
  

  .transition_to_signup {
    color: rgba(255,255,255,1);
    font-size: 16px;
    left: 0;
    width: 100%;
    margin: 30px 10px;
    font-weight: Regular;
    text-decoration: none;
    font-size: 16px;
    opacity: 1;
    text-align: center;
    cursor: pointer;
  }
  .transition_to_signup:hover {
    text-decoration: underline;
    color: #3391FF;
  }

  .error_msg {
    color: rgba(255,0,0,1);
    margin: 0px 0px 10px 0px;
    padding: 5px 0px 10px 0px;
    width: 100%;
    font-weight: Regular;
    font-size: 16px;
    opacity: 1;
    text-align: center;
  }

  .sso_logins {
    margin-top: 10px;
  }

  .disclaimer {
    font-size: 10px;
    max-width: 400px;
    margin: 10px 0px 0px 0px;
    text-align: center;
    color: #C2C2C2
  }