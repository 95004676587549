.demo_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.demo_container_item {
    margin: 25px 0px;
}

.demo_header {
    margin: 40px 0px;
    font-weight:bold;
}

.demo_iframe {
    width: 80%;
    min-height: 300px;
}

.demo_cta {
    max-width: 300px;
}

@media only screen and (min-width: 768px) {
    .demo_iframe {
        width: 80%;
        height: 100%;
        min-height: 700px;
    }
}