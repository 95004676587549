.payments_success_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.back_to_dashboard_button {
  margin-top: 30px;
}

.payments_success_inner_container {
  margin: 80px 0px 0px 0px;
}
