.Collapsible {
    background-color: #EEEEEE;
    text-align: left;
    color: black;
    font-size: 24px;
    border-radius: 3px;
    padding: 10px;
    font-weight: bold;
    margin: 3px 0px;
}

.Collapsible__trigger {
    width: 100% !important;
    cursor: pointer !important;
    text-decoration: underline;
}

.faq-inner {
    font-size: 16px;
    font-weight: normal;
    margin: 15px 0px;
}

.faq-container {
    margin: 20px;
    max-width: 600px;
    height: 100%;
}

.faq-component {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Collapsible__contentInner {
    margin: 15px 0px;
}

.faq-header {
    font-size: 28px;
}

.header_font {
    font-family: 'Playfair Display', serif;
    font-weight: 300;
    text-align: center;
}
