h1 {
    font-size: 36px;
}

h2 {
    font-size: 26px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

p {
    font-size: 18px;
    text-align: left;
}

h1, h2, h3, h4, h5, h6, ul, li {
    text-align: left;
}

h1, h2, h3 {
    margin: 30px 0px;
}

.privacy_container {
    margin: 0px 20px;
}

@media only screen and (min-width: 768px) {
    .privacy_container {
        margin: 0px 20%;
    }
    
}