.errorpage_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.errorpage_text_container {
    margin: 0% 20px;
}

.errorpage_text_container > iframe {
    width: 100%;
    max-width: 500px;
    min-height: 400px;
}

@media only screen and (min-width: 768px) {
    .errorpage_text_container {
        width: 60%;
    }    
}