.pricing-container {
  width: 100%;
}

.pricing-inner-container {
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  padding: 0px 20px;
}

.pricing-inner-container > p {
  width: 100%;
}

.pricing-option {
  max-width: 100%;
  width: 100%;
  text-align: center;
  border: 1px solid black;
  border-radius: 8px;
  padding: 30px 10px;
  margin: 10px 0px;
  background-color: #1f1f1f;
}

.pricing-options-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.features-list {
  font-size: 1em;
  margin: 20px 0px;
  list-style: none;
}

.price {
  font-size: 2.5em;
}

.strikethrough {
  text-decoration: line-through;
  padding-right: 3px;
}

.limited_time_offer {
  text-align: center;
  font-size: 14px;
  padding: 3px;
  border-radius: 3px;
  background-color: #1a71ff;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  .pricing-option {
    max-width: 50%;
    width: 50%;
    text-align: center;
    border: 1px solid black;
    border-radius: 8px;
    padding: 30px 10px;
    margin: 40px 10px;
    background-color: #1f1f1f;
  }

  .pricing-options-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .features-list {
    font-size: 0.65em;
    min-height: 300px;
    margin: 20px 0px;
  }

  .pricing-inner-container > p {
    width: 60%;
  }
}

.subscription_disclaimer {
  text-align: center;
  padding: 20px 0px;
}
