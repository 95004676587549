.homepage {
    width: 100%;
}

.header_font {
    font-family: 'Playfair Display', serif;
    font-weight: 300;
    text-align: center;
}

.homepage_hero {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #3f3f3f;
    color: white;
    border-bottom: 1.5px solid #1f1f1f;
}

.hero_banner_text {
    font-size: 2em;
    text-align: left;
    width: 100%;
}

.homepage_image {
    width: 100%;
    height: 100%;
    min-height: 700px;
    background-image: url('./sample-vert.png');
    background-size: 100%;
    background-repeat: no-repeat;
}

.homepage_container {
    margin: 0px 10%;
    width: 100%;
    height: 100%;
    max-height: 900px;
    overflow: hidden;
}

.call_to_action_containers {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    margin: 20px 0px 0px 0px;
}

.call_to_action_containers > div {
    margin: 10px 0px;
}

.cta_minor, .cta_button_homepage_inner {
    width: 150px;
    font-size: 18px;
    cursor: pointer;
}

.cta_button_homepage_inner {
    width: calc(100%);
    height: 100%;
    border: 0;
    background-color: rgba(91,91,91,0);
    color: rgba(255,255,255,1);
    position: absolute;
    top: 14px;
    left: 0px;
    font-weight: Regular;
    font-size: 20px;
    opacity: 1;
    cursor: pointer;
    background: rgba(60,138,255,1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(113,169,253,1);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    height: 50px;
    cursor: pointer;
}

.hero_container_left {
    display: flex;
    justify-content: left;
    flex-direction: column;
    align-items: left;
    width: 70%;
    margin: 100px 0px;
}

.hero_banner_description {
    font-size: 18px;
}

.homepage_explainer {
    margin: 70px 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.homepage_explainer_subsection {
    text-align: left;
    flex-grow: 1;
    width: 100%;
    margin: 30px 0px;
    padding: 0px 15px;
}

.homepage_explainer_subsection_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px 10%;
}

.homepage_explainer_subsection > h4 {
    font-size: 24px;
    font-weight: bold;
}

.homepage_explainer_subsection > p {
    font-size: 0.7em;
}

.get_started {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 120px 0px;
}

.get_started > p {
    font-size: 0.6em;
}

.outbound_link {
    color: white !important;
}

.homepage_testimonial_card {
    background-color: #f0f0f0;
    color: black;
    max-width: 500px;
    border-radius: 5px;
    padding: 30px;
}

.homepage_testimonial_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
}

.homepage_testimonial_card > p {
    text-align: right;
    font-size: 24px;
}

.homepage_testimonials {
    margin: 40px 0px;
}


.overlaid_video_background {
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hidden {
    display: none;
}

.overlaid_dismiss_button {
    position: fixed;
    top: 15px;
    left: 24px;
}

.overlaid_video_asset > iframe {
    width: 400px;
    height: 300px;
}

.testimonial_signature {
    font-size: 20px !important;
}

@media only screen and (min-width: 768px) {
    .homepage_image {
        width: 100%;
        height: 100%;
        min-height: 700px;
        background-image: url('./sample.png');
        background-size: 100%;
        background-repeat: no-repeat;
    }    

    .cta_minor, .cta_button_homepage_inner {
        width: 180px;
        font-size: 18px;
    }

    .homepage_explainer_subsection_container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 30px 10%;
    }

    .homepage_explainer_subsection {
        text-align: left;
        flex-grow: 1;
        width: 33%;
        padding: 0px 15px;
    }
    
    .overlaid_video_asset > iframe {
        width: 1280px;
        height: 720px;
    }

    .call_to_action_containers {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        margin: 20px 0px 0px 0px;
    }
}