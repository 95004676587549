.subscribe-container {
  width: 100%;
  height: 100%;
}

.billing_input_container {
}

.form-group > label {
  font-size: 14px;
  min-width: 100px;
  text-align: left;
  color: black;
  font-weight: bold;
}

.form-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}

.billing_input_container {
  background-color: #eeeeee;
  padding: 20px 20px;
  border-radius: 8px;
}

.subscribe-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkout_data_entry_panel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 80%;
  margin: 40px 0px;
}

.billing_address_card {
  width: 50%;
  padding: 0px 10% 0px 0px;
}

.pricing_headers {
  font-weight: bold;
}

.cc_card {
  margin: 20px 0px;
}

.checkout_subtotal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.separator {
  flex-grow: 99;
}

.checkout_subtotal_preview {
  width: 40%;
}

.subscribe-container {
  margin: 30px 0px;
}

.value_prop_line {
  font-size: 0.3em;
  line-height: 100%;
  color: #bbbbbb;
}

.subtotal_item {
  font-weight: bold;
}
